@import "style/_colors";

.App {
  text-align: center;
}

.beer-parent {
  position: relative;
  top: 0;
  left: 0;

  img.outline {
    position: relative;
    top: 0;
    left: 0;
  }
  img.inside {
    position: absolute;
    top: 0;
    left: 0;
  }
}

img.beer {
  height: 40vmin;
}

div.beer-container {
  display: flex;

  div.counter {
    font-size: 32vmin;
    height: 100%;
    align-items: center;

    min-width: 1.2em;
    text-align: right;

    &.finished {
      color: $yellow;
    }
  }
}

.spin-img {
  animation: logo-spin infinite 15s ease;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: $white;
}

@keyframes logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(1080deg);
  }
}
