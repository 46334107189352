@import "style/_colors";

.result-beer-parent {
  position: relative;
  top: 0;
  left: 0;

  img.outline {
    position: relative;
    top: 0;
    left: 0;
  }
  img.inside {
    position: absolute;
    top: 0;
    left: 0;
  }

  margin-bottom: 10vmin;
}

img.result-beer {
  height: 13vmin;
}

div.result-container {
  display: flex;
  flex-direction: row;
}

.Remote {
  text-align: center;
}

.Remote-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(8px + 6vmin);
  color: $white;
}

button.link {
  background-color: $blue;
  padding: 0;
  margin: 0;
}
