@import "style/_colors";

body {
  margin: 0;
  padding: 0;

  font-family: 'Fira Mono', monospace;

  background-color: $blue;
}
